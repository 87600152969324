<template>
  <div>
    <br />
    <div class="row">
      <div
        class="form-group col-sm-2"
        style="margin-left: -8px;"
        v-if="$parent.turno.estado == 8"
      >
        <button
          type="button"
          class="btn btn-sm bg-navy"
          v-if="flag == false && $store.getters.can('tif.turnos.asignarPuntos')"
          @click="agregar()"
        >
          Agregar Punto Visita
        </button>
      </div>
      <div
        class="form-group col-sm-1"
        style="margin-left: -45px;"
        v-if="
          flag == false &&
            $store.getters.can('tif.turnos.renderizarRecorridos') &&
            $parent.turno.estado < 9 &&
            this.countPunto > 0
        "
      >
        <label class="badge bg-frontera text-white mr-2">
          <input
            type="checkbox"
            @click="renderizarRecorridos($parent.turno_id)"
          />

          <span class="badge bg-info m-1" style="font-size:11px;"
            >Cálculo Recorridos</span
          >
        </label>
      </div>
      <div class="card" v-if="flag == true">
        <div class="card-header">
          <h5 class="card-title"><i class="fas fa-map"></i> Puntos Visita</h5>
        </div>
        <div class="ribbon-wrapper ribbon-lg">
          <div class="ribbon bg-primary">Puntos Visita</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-md-3">
              <div class="row ml-1">
                <label for="tif_sitio">Nombre Sitio</label>
                <v-select
                  :class="[
                    $v.form.tif_sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="sitio"
                  placeholder="Nombre"
                  label="nombre"
                  :options="listasForms.sitios"
                  @input="getSelectSitio()"
                  class="form-control form-control-sm p-0"
                ></v-select>
              </div>
            </div>
            <div class="form-group col-md-3">
              <div class="row ml-1">
                <label for="fecha_inicio">Fecha y Hora Llegada</label>
                <input
                  type="date"
                  v-model="form.llegada_fecha"
                  style="font-size: 13px"
                  class="form-control form-control-sm col-md-7"
                  :class="
                    $v.form.llegada_fecha.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validaFechas()"
                />
                <input
                  v-model="form.hora_llegada"
                  type="text"
                  class="form-control form-control-sm col-md-5"
                  style="font-size: 13px"
                  v-mask="{
                    mask: '99:99:99',
                    hourFormat: '23',
                  }"
                  :class="
                    $v.form.hora_llegada.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validaFechas()"
                />
              </div>
            </div>
            <div class="form-group col-md-3">
              <div class="row ml-1">
                <label for="fecha_fin">Fecha y Hora Salida</label>
                <input
                  type="date"
                  v-model="form.salida_fecha"
                  style="font-size: 13px"
                  class="form-control form-control-sm col-md-7"
                  :class="
                    $v.form.salida_fecha.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validaFechas()"
                />
                <input
                  v-model="form.hora_salida"
                  type="text"
                  class="form-control form-control-sm col-md-5"
                  style="font-size: 13px"
                  v-mask="{
                    mask: '99:99:99',
                    hourFormat: '23',
                  }"
                  :class="
                    $v.form.hora_salida.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validaFechas()"
                />
              </div>
            </div>
            <div class="form-group col-md-2">
              <div class="row ml-1">
                <label>Tiempo Punto</label>
                <input
                  type="number"
                  v-model="form.tiempo_punto"
                  disabled
                  class="form-control form-control-sm"
                  placeholder="Tiempo"
                  :class="
                    $v.form.tiempo_punto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="row ml-1">
                <label>Actividad</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.actividad"
                  :class="
                    $v.form.actividad.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="actividad in listasForms.actividades"
                    :key="actividad.numeracion"
                    :value="actividad.numeracion"
                  >
                    {{ actividad.descripcion }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-2">
              <div class="row ml-1">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  v-if="
                    flag == true &&
                      $store.getters.can('tif.turnos.asignarPuntos')
                  "
                  v-show="!$v.form.$invalid"
                  @click="save()"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-primary"
                  title="Cancelar Cambio"
                  v-if="flag == true"
                  @click="cancelarCambio()"
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table
        class="table table-sm table-hover"
        v-if="$parent.det_puntos.length > 0"
      >
        <thead class="thead-dark">
          <tr class="text-center">
            <th>Nombre Sitio</th>
            <th>Llegada</th>
            <th>Salida</th>
            <th>Tiempo en punto</th>
            <th>Actividad</th>
            <th>Tipo Descargue</th>
            <th colspan="2">Cant. producto</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in $parent.det_puntos"
            :key="item.id"
            :class="
              item.actividad == 4
                ? 'table-warning'
                : '' || item.actividad == 5
                ? 'table-danger'
                : ''
            "
          >
            <th v-if="item.tif_sitio">{{ item.tif_sitio.nombre }}</th>
            <th v-else></th>
            <td class="text-center">{{ item.fecha_llegada }}</td>
            <td class="text-center">
              {{ item.fecha_salida }}
            </td>
            <td class="text-center">{{ item.tiempo_punto }}</td>
            <td class="text-center" v-if="item.actividad != 1">
              {{ item.tipoActividad }}
            </td>
            <td v-else>
              <select
                class="form-control form-control-sm"
                v-model="item.actividad"
                @change="saveActividad(item)"
                v-if="$store.getters.can('tif.turnos.asignarActividad')"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="actividad in listasForms.actividades"
                  :key="actividad.numeracion"
                  :value="actividad.numeracion"
                >
                  {{ actividad.descripcion }}
                </option>
              </select>
            </td>
            <td
              v-if="
                item.actividad == 2 &&
                  ($parent.turno.producto_id == 31 ||
                    $parent.turno.producto_id == 32) &&
                  item.tipo_descargue != null
              "
              class="text-center"
            >
              {{ item.tipoDescargue }}
            </td>
            <td
              v-else-if="
                item.actividad == 2 &&
                  ($parent.turno.producto_id == 31 ||
                    $parent.turno.producto_id == 32) &&
                  item.tipo_descargue == null
              "
            >
              <select
                class="form-control form-control-sm"
                v-model="item.tipo_descargue"
                @change="saveTipoDescargue(item)"
                v-if="$store.getters.can('tif.turnos.asignarTipoDescargue')"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tip in listasForms.tipos_descargues"
                  :key="tip.numeracion"
                  :value="tip.numeracion"
                >
                  {{ tip.descripcion }}
                </option>
              </select>
            </td>
            <td class="text-center" v-else></td>           
            <td
              v-if="
                item.actividad == 2 &&
                  $store.getters.can('tif.turnos.asignarProducto') &&                
                  ($parent.turno.estado != 10 || $parent.turno.estado != 9)
              "
            >
              <input
                type="text"
                v-model="item.cant_producto"
                class="form-control form-control-sm"
                placeholder="Ingrese cant. producto"
                @keyup.enter="saveCantProducto(item)"
              />
            </td>
            <td class="text-center" v-else>{{ item.cant_producto }}</td>
            <td>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                v-if="
                  item.tipo_creacion == 1 &&
                    $store.getters.can('tif.turnos.puntosDelete') &&
                    $parent.turno.estado < 9
                "
                @click="destroy(item)"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-if="$parent.det_puntos.length == 0">
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontraron Puntos de Visita para este Viaje!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TifTurnoInfoPuntos",
  components: {
    vSelect,
  },

  data() {
    return {
      flag: false,
      sitio: {},
      det_puntos: {},
      countPunto: 0,
      turno: null,
      form: {
        tif_sitio_id: null,
        llegada_fecha: null,
        hora_llegada: null,
        salida_fecha: null,
        hora_salida: null,
        tiempo_punto: null,
        actividad: null,
        tipo_creacion: 1,
      },
      listasForms: {
        actividades: [],
        sitios: [],
        tipos_descargues: [],
      },
    };
  },
  validations() {
    return {
      form: {
        tif_sitio_id: {
          required,
        },
        llegada_fecha: {
          required,
        },
        hora_llegada: {
          required,
        },
        salida_fecha: {
          required,
        },
        hora_salida: {
          required,
        },
        tiempo_punto: {
          required,
        },
        actividad: {
          required,
        },
      },
    };
  },

  methods: {
    saveCantProducto(item) {
      axios({
        method: "PUT",
        url: "/api/tif/turnos/updatePuntos",
        data: {
          datos: item,
        },
      }).then((response) => {
        this.$swal({
          icon: "success",
          title: "Se guardo exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.det_puntos = response.data;
      });
    },

    saveActividad(item) {
      axios({
        method: "PUT",
        url: "/api/tif/turnos/updateActividad",
        data: {
          datos: item,
        },
      }).then((response) => {
        this.$swal({
          icon: "success",
          title: "Se guardo exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.det_puntos = response.data;
      });
      this.$parent.getDatosPuntos();
    },

    saveTipoDescargue(item) {
      axios({
        method: "PUT",
        url: "/api/tif/turnos/updateTipoDescargue",
        data: {
          datos: item,
        },
      }).then((response) => {
        this.$swal({
          icon: "success",
          title: "Se guardo exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.det_puntos = response.data;
      });
      this.$parent.getDatosPuntos();
    },

    getActividad() {
      axios.get("/api/lista/116").then((response) => {
        this.listasForms.actividades = response.data;
      });
    },

    getTipoDescargue() {
      axios.get("/api/lista/172").then((response) => {
        this.listasForms.tipos_descargues = response.data;
      });
    },

    agregar() {
      this.$parent.cargando = true;
      this.flag = true;
      this.listasForms.sitios = [];
      this.getSitio();
      this.$parent.cargando = false;
    },

    getSitio() {
      this.$parent.cargando = true;
      axios
        .get("/api/tif/conciliaciones/sitio", {
          params: {
            tif_ruta_id: this.$parent.turno.tif_ruta_id,
          },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
          this.$parent.cargando = false;
        });
    },

    getSelectSitio() {
      if (this.sitio) {
        this.form.tif_sitio_id = this.sitio.id;
      }
    },

    validaFechas() {
      this.diferenciaTiempo();
      let fechaInTurno = this.$parent.turno.fecha_ini;
      let fechaFinTurno = this.$parent.turno.fecha_fin;
      let fechaLlegada = this.form.llegada_fecha + " " + this.form.hora_llegada;
      let fechaSalida = this.form.salida_fecha + " " + this.form.hora_salida;
      let puntosVisita = this.$parent.det_puntos;

      if (
        this.form.llegada_fecha &&
        this.form.hora_llegada &&
        this.form.salida_fecha &&
        this.form.hora_salida
      ) {
        for (let i = 0; i < puntosVisita.length; i++) {
          // Se valida que las fechas del formulario no concuerden con las fechas el turno
          if (
            fechaLlegada >= puntosVisita[i].fecha_llegada &&
            fechaLlegada <= puntosVisita[i].fecha_salida
          ) {
            this.form.llegada_fecha = null;
            this.form.hora_llegada = null;
            this.form.tiempo_punto = null;
            this.$swal({
              customClass: {
                container: "my-swal",
              },
              icon: "error",
              title: `El rango de la fecha llegada ya existe...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          }
          if (
            fechaSalida >= puntosVisita[i].fecha_llegada &&
            fechaSalida <= puntosVisita[i].fecha_salida
          ) {
            this.form.salida_fecha = null;
            this.form.hora_salida = null;
            this.form.tiempo_punto = null;
            this.$swal({
              customClass: {
                container: "my-swal",
              },
              icon: "error",
              title: `La fecha de salida del vehiculos ya se encuentra...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        }

        if (fechaLlegada < fechaInTurno || fechaLlegada > fechaFinTurno) {
          this.form.llegada_fecha = null;
          this.form.hora_llegada = null;
          this.form.tiempo_punto = null;

          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `La fecha llegada no se encuentran en el rango de la fecha inicial del turno...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }

        if (fechaSalida > fechaFinTurno || fechaSalida < fechaInTurno) {
          this.form.salida_fecha = null;
          this.form.hora_salida = null;
          this.form.tiempo_punto = null;

          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `La fecha salida no se encuentran en el rango de las fecha fin del turno...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }

        // Se valida que la fecha inicial sea menor que la fecha final
        if (this.form.llegada_fecha > this.form.salida_fecha) {
          this.form.llegada_fecha = null;
          this.form.salida_fecha = null;
          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `La fecha llegada no puede ser mayor a la fecha salida...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }

        // Se valida que la hora inicial sea menor que la hora final
        if (this.form.hora_llegada > this.form.hora_salida) {
          this.form.hora_llegada = null;
          this.form.hora_salida = null;
          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `La hora llegada no puede ser mayor a la hora salida...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      }
    },

    diferenciaTiempo() {
      //Diferencia tiempo fecha inicial e fecha final
      let fecha_menor = new Date(
        this.form.llegada_fecha + " " + this.form.hora_llegada
      ).getTime();
      let fecha_mayor = new Date(
        this.form.salida_fecha + " " + this.form.hora_salida
      ).getTime();
      var diff = fecha_mayor - fecha_menor;
      var result = diff / (1000 * 60);
      this.form.tiempo_punto = result.toFixed();
    },

    cancelarCambio() {
      this.$parent.cargando = true;
      this.$parent.getDatosPuntos();
      this.limpiarForm();
      this.flag = false;
      this.$parent.cargando = false;
    },

    limpiarForm() {
      this.sitio = null;
      this.form = {
        tif_sitio_id: null,
        fecha_llegada: null,
        hora_llegada: null,
        fecha_salida: null,
        hora_salida: null,
        tiempo_punto: null,
        actividad: null,
      };
    },

    async save() {
      this.form.tif_turno_id = this.$parent.turno_id;
      this.$parent.cargando = true;
      this.form.fecha_llegada =
        this.form.llegada_fecha + " " + this.form.hora_llegada;
      this.form.fecha_salida =
        this.form.salida_fecha + " " + this.form.hora_salida;
      this.countPunto++;
      this.form.tipo_creacion = 1;
      axios({
        method: "PUT",
        url: "/api/tif/turnos/puntos/save",
        data: this.form,
      })
        .then(async (response) => {
          this.$parent.cargando = false;
          await this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "success",
            title: "Los datos del punto se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.$parent.getDatosPuntos();
          this.limpiarForm();
          this.flag = false;
          await this.$swal({
            customClass: {
              container: "my-swal",
            },
            title:
              "Al terminar la creación de los puntos de visita, recuerde dar click en el check (Cálculo Recorrido)",
            /* text: "Crear recorridos", */
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Aceptar!",
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },

    destroy(item) {
      this.$swal({
        customClass: {
          container: "my-swal",
        },
        title:
          "Esta seguro de eliminar la visita al sitio: " +
          item.tif_sitio.nombre +
          "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tif/turnos/destroyPunto/" + item.id).then(() => {
            this.$swal({
              customClass: {
                container: "my-swal",
              },
              icon: "success",
              title: "Se eliminó el Punto exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.getDatosPuntos();
          });
        }
      });
    },

    renderizarRecorridos(turno) {
      this.turno = this.$parent.turno;
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/tif/turnos/crearRecorridos/" + turno,
        data: this.turno,
      })
        .then((response) => {
          this.$parent.getDatosPuntos();
          this.flag = false;
          this.$parent.cargando = false;

          this.$swal({
            customClass: {
              container: "my-swal",
            },
            icon: "success",
            title: "Se renderizo exitosamente los recorridos...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },
  },

  mounted() {
    this.getActividad();
    this.getTipoDescargue();
  },
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
